import {drop, kvMap, map} from "./utils";
import {send, types} from "./tracking";

const context_id = 'nav_local_context';
const contextSelector = '.nav_local-content';
const listSelector = '.nav_local-category';
const itemSelector = 'li:not(.nav_link-headline)';

export function trackExpanderFeaturesForDesktop(action, target) {
    let contextEl = window.document.querySelector(contextSelector);
    let contextData = contextEl.dataset;
    let labels = [('nav_LocalContext' + contextData.suffix),
                  [contextData.title],
                 ];
    if (contextData.navLocalCluster) {
        labels.push('nav_LocalCluster', [contextData.navLocalCluster])
    }
    let contextFeature = {
        id: context_id,
        name: 'LocalNavigation-Context',
        status: 'selected',
        labels: kvMap(labels)
    };

    let allCategories = Array.from(contextEl.querySelectorAll(listSelector));
    let categoryEl = target.parentNode;
    let position = allCategories.findIndex((cat) => cat == categoryEl) + 1;

    let categoryData = categoryEl.dataset;
    let localListId = 'nav_local_list_' + position;
    let categoryFeature = {
        id: localListId,
        name: 'LocalNavigation-List',
        status: (action === 'expand') ? 'expanded' : 'collapsed',
        parentId: context_id,
        position: position,
        labels: kvMap([('nav_LocalList' + categoryData.suffix), [categoryData.title],
                       'nav_LocalType', [categoryData.navType],
                      ]),
    };

    let visibleCount = target.parentNode.querySelector('.nav_local-links').querySelectorAll(itemSelector).length;
    let hiddenLinks = target.parentNode.querySelectorAll('.nav_local-hidden > .nav_local-links > .nav_local-link');

    let linkFeatures = map((el, i) => {
        let dataset = el.dataset;
        let position = i + visibleCount + 1;

        return {
            id: 'nav_local_item_' + position,
            name: 'LocalNavigation-Item',
            status: (action === 'expand') ? 'visible' : 'collapsed',
            parentId: localListId,
            position: position,
            labels: kvMap([('nav_LocalItem' + dataset.suffix), [dataset.title],
                'nav_LocalType', [dataset.navType]])
        };
    }, hiddenLinks);

    let features = [contextFeature, categoryFeature];
    features = features.concat(linkFeatures);

    send(types.action, {}, {name: action, features: features});
}
