import {kvMap, map, mobilep, take} from "./utils";
import {send, types} from "./tracking";

const context_id = 'nav_local_context';

const maxTouchElements = 3;

const contextSelector = '.nav_local-content';
const listSelector = '.nav_local-category';
const itemSelector = 'li:not(.nav_link-headline)';

const contextName = 'LocalNavigation-Context';
const listName = 'LocalNavigation-List';
const itemName = 'LocalNavigation-Item';

let pageContextEl =
    () => window.document.querySelectorAll(contextSelector)[0] || {};

let contextListEls =
    (contextEl) => contextEl.querySelectorAll(listSelector) || [];

let listItemEls =
    (listEl) => listEl.querySelectorAll(itemSelector) || [];

let datasetStatus = (dataset) => (dataset.selected === 'true') ? 'selected' : 'visible';

const noop = () => {};

/*                                                                       */
const device = mobilep() ? 'mobile' : 'desktop';

function pagetype() {
    let dataset = pageContextEl().dataset || {};
    return dataset.pagetype || 'unknown';
}

const VARIANT = (() => {
    let dataset = pageContextEl().dataset || {};
    return dataset.variant || 'status-quo';
})();

let withListInvisibleHeadline = (features) => {
    features.forEach((feature) => {
        feature['status'] = 'selected';
    });
    return features;
};

/*       */

let contextFeatures = (contextEl, breakpoint) => {
    let dataset = contextEl.dataset || {};
    let labels = [('nav_LocalContext' + dataset.suffix), [dataset.title]]
    if (dataset.navLocalCluster) {
        const clusterVal = breakpoint === "mobile" ?
            dataset.navLocalCluster + "_filter_sheet" : dataset.navLocalCluster
        labels.push('nav_LocalCluster', [clusterVal])
    }
    return [{
        'id': context_id,
        'name': contextName,
        'status': 'selected',
        'labels': kvMap(labels)
    }];
};

/*    */

let listFeatures = (listEl, i, ...mods) => {
    let dataset = listEl.dataset || {};
    let titlekey = ('nav_LocalList' + dataset.suffix);
    let features = [{'id': 'nav_local_list_' + i,
        'parentId': context_id,
        'name': listName,
        'status': datasetStatus(dataset),
        'position': i + 1,
        'labels': kvMap(['nav_LocalType', [dataset.navType],
            titlekey, [dataset.title]])}];
    return mods.reduce((fs, mod) => mod(fs, dataset),
        features);
};

/*    */

let itemFeatures = (itemEl, i, j) => {
    let dataset = itemEl.dataset || {};
    return [{'id': 'nav_local_item_' + i + '_' + j,
        'parentId': 'nav_local_list_' + i,
        'name': itemName,
        'position': j + 1,
        'status': datasetStatus(dataset),
        'labels': kvMap(['nav_LocalType', [dataset.navType],
            ('nav_LocalItem' + dataset.suffix), [dataset.title]])}];
};

/*     */

let trackPageView = () => {
    let contextEl = pageContextEl();
    let features = trackfn(device, pagetype(), 'context')(contextEl);

    send(types.viewTracking, features);
};

let trackPanelView = () => {
    let contextEl = pageContextEl();
    let features = trackfn(device, pagetype(), 'context')(contextEl);

    send(types.action, {}, {name: 'open', features: features});
};

/*               */

let initialized = false;

let trackfns = {
    mobile: {
        products: {
            context: (contextEl) => {
                let ctx = contextFeatures(contextEl, "mobile");
                let listEls = contextListEls(contextEl);
                let listFeatures = map(trackfn(device, pagetype(), 'list'),
                    listEls);
                return ctx.concat(...listFeatures);
            },
            list: (listEl, i) => {
                let lst = listFeatures(listEl, i, withListInvisibleHeadline);
                let itemEls = listItemEls(listEl);
                let itemFeatures =
                    map((itemEl, j) => trackfn(device, pagetype(), 'item')(itemEl, i, j),
                        itemEls);
                return lst.concat(...itemFeatures);
            },
            item: itemFeatures,
            track: () => {
                if (! initialized) {
                    window.o_global.eventQBus.on('ftfind.filterSheet.openedNavigation', trackPanelView);
                    initialized = true;
                }
            }
        }
    },
    desktop: {
        module: {
            context: (contextEl) => {
                let ctx = contextFeatures(contextEl, "desktop");
                let listEls = contextListEls(contextEl);
                let listFeatures = map(trackfn(device, pagetype(), 'list'),
                    listEls);
                return ctx.concat(...listFeatures);
            },
            list: (listEl, i) => {
                let lst = listFeatures(listEl, i);
                /*                                   */
                let listUl = listEl.querySelectorAll('ul.nav_local-links')[0];
                let itemEls = listItemEls(listUl);
                let itemFeatures =
                    map((itemEl, j) => trackfn(device, pagetype(), 'item')(itemEl, i, j),
                        itemEls);
                return lst.concat(...itemFeatures);
            },
            item: itemFeatures,
            track: trackPageView
        },
        entry: {
            context: (contextEl) => {
                let ctx = contextFeatures(contextEl, "desktop");
                let listEls = contextListEls(contextEl);
                let listFeatures = map(trackfn(device, pagetype(), 'list'),
                    listEls);
                return ctx.concat(...listFeatures);
            },
            list: (listEl, i) => {
                let lst = listFeatures(listEl, i);
                let itemEls = listItemEls(listEl);
                let itemFeatures =
                    map((itemEl, j) => trackfn(device, pagetype(), 'item')(itemEl, i, j),
                        itemEls);
                return lst.concat(...itemFeatures);
            },
            item: itemFeatures,
            track: trackPageView
        },
        products: {
            context: (contextEl) => {
                let ctx = contextFeatures(contextEl, "desktop");
                let listEls = contextListEls(contextEl);
                let listFeatures = map(trackfn(device, pagetype(), 'list'),
                    listEls);
                return ctx.concat(...listFeatures);
            },
            list: (listEl, i) => {
                let lst = listFeatures(listEl, i);
                let itemEls = listItemEls(listEl);
                let itemFeatures =
                    map((itemEl, j) => trackfn(device, pagetype(), 'item')(itemEl, i, j),
                        itemEls);
                return lst.concat(...itemFeatures);
            },
            item: itemFeatures,
            track: trackPageView
        }
    }
};

let trackfn = (device, pagetype, name) => {
    return (((trackfns[device] || {})[pagetype] || {})[name]) || noop;
};

/*  */

export function initViewTracking() {
    trackfn(device, pagetype(), 'track')();
}

